<template>
  <mobile-screen :header="true" screen-class="edit-form-screen add-company">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="companies-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left"></icon>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("manage-companies", "select-company-id", "title")
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul v-if="companyTypes && companyTypes.data" class="clebex-item-section">
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="companyTypesRadioNotDefined"
                  type="checkbox"
                  :disabled="!selectedCompanyType && !notDefined"
                  v-model="notDefined"
                />
                <label for="companyTypesRadioNotDefined"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              Unset
            </dd>
          </dl>
        </div>
      </li>
      <li
        v-for="companyType in companyTypes.data"
        :key="companyType.id"
        class="clebex-item-section-item no-bottom-border light"
      >
        <div
          class="clebex-item-content-wrapper cursor-pointer"
          @click="setType(companyType)"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label cursor-pointer">
              {{ companyType.description }}
            </label>
          </div>
          <span v-if="companyTypeId === companyType.id" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <div class="svg-icon">
                <svg width="12" height="12" style="width: 12px; height: 12px;">
                  <use xlink:href="#cx-app1-checkmark"></use>
                </svg>
              </div>
            </span>
          </span>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "CompaniesTypeSelection",
  data() {
    return {
      companyTypeId: null,
      notDefined: false
    };
  },
  computed: {
    ...mapState("companies", ["companyTypes", "selectedCompanyType"])
  },
  watch: {
    notDefined() {
      if (this.notDefined) {
        this.companyTypeId = null;
        this.setCompanyType(null);
      }
    },
    selectedCompanyType() {
      if (this.selectedCompanyType) {
        this.notDefined = false;
        this.$router.push({ name: this.backLinkName });
      }
    }
  },
  created() {
    if (this.selectedCompanyType) {
      this.companyTypeId = this.selectedCompanyType.id;
    }
    if (!this.companyTypes) this.getCompanyTypes();
  },
  methods: {
    ...mapActions("companies", ["getCompanyTypes", "setCompanyType"]),
    setType(companyType) {
      this.companyTypeId = companyType.id;
      this.setCompanyType(companyType);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
